$(() => {
  $(document).on('ajax:beforeSend', '#currency_revaluation_form', function () {
    $('#spinner-modal-trigger').trigger('click');
  });

  $(document).on('ajax:success', '#currency_revaluation_form', function (event) {
    $('#spinner-modal button').trigger('click');
    const [data, status, xhr] = event.detail;
    console.log('Form submitted successfully:', data);
  });

  $(document).on('ajax:error', '#currency_revaluation_form', function (event) {
    $('#spinner-modal button').trigger('click');
    const [data, status, xhr] = event.detail;
    console.error('Error submitting form:', data);
  });

  $(document).on('change', '.input-submit', function (event) {
    $('#currency_revaluation_form #form-update-button').trigger('click');
  });

  $(document).on('change', '#currency_revaluation_currency_id', function (event) {
    $('#currency-revaluations-table tbody').empty();
    $('#currency_revaluation_form #form-update-button').trigger('click');
  });

  $(document).on('change', '#add_account', function (event) {
    $('#add-account-button').toggle(!!this.value);
  });

  $(document).on('click', '#add-account-button', function (event) {
    $('#currency_revaluation_form #form-update-button').trigger('click');
  });

  $(document).on('click', '.remove-account-button', function (event) {
    const row = $(this).parent().parent().parent();
    if (!row) return;
    $('#remove_account').val(row.attr('data-account'));
    row.remove();
    $('#currency_revaluation_form #form-update-button').trigger('click');
  });
});

function showAlert(message) {
  var errors = `
  <ul class="flex flex-wrap gap-x-4 items-center">
    <li>${message}</li>
  </ul>
    `;
  $('#notification-modal .modal-body').html(errors);
  $('#notification-modal-button').trigger('click');
}

document.addEventListener('DOMContentLoaded', function () {
  const submitLink = document.getElementById('approval-link');
  if (!submitLink) return;
  submitLink.addEventListener('click', function (event) {
    event.stopPropagation();
    event.preventDefault();

    const comment = document.getElementById('currency_revaluation_audit_comment').value;

    if (!comment) {
      showAlert('Remark is MISSING!');
      return;
    }

    if (!confirm('Are you sure?')) return;

    const url = new URL(submitLink.href);
    url.searchParams.set('audit_comment', comment);
    console.log(comment);

    // Redirect to the updated URL
    window.location.href = url.toString();
  });
});
